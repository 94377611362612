import { TFunction } from 'i18next';

export enum Lang {
  en = 'en',
  ru = 'ru',
}

export type ContextValue = {
  lang: Lang;
  t: TFunction;
};
