import { bootstrap } from 'src/bootstrap';

bootstrap()
  .catch((error) => {
    import('src/helpers/renderErrorScreen').then((m) =>
      m.renderErrorScreen(error)
    );
  })
  .finally(() => {
    const loader = document.getElementById('loader');
    loader?.classList.add('completed');
  });
