import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import i18next from 'i18next';
import { core } from '@shared/modules/api';
import { Lang, ContextValue } from '../types';
import { context } from '../context';

interface Props {
  children?: ReactNode;
}

export const Provider: FC<Props> = ({ children }) => {
  const [lang, setLang] = useState(Lang.ru);

  useEffect(() => {
    (window as any).changeLanguage = (l: Lang) => {
      core.setVariable('lang', l);
      setLang(l);
    };
  }, []);

  const value = useMemo(
    (): ContextValue => ({
      lang,
      t: i18next.getFixedT(lang),
    }),
    [lang]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};
