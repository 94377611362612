import { localStorage } from 'src/localStorage';

(window as any).projectDebugger = {
  toggleDebugPreact: () => {
    localStorage.set('debugPreact', !localStorage.get('debugPreact'));
    window.location.reload();
  },
};

if (
  process.env.REACT_APP_PREACT &&
  (process.env.NODE_ENV === 'development' || localStorage.get('debugPreact'))
) {
  // eslint-disable-next-line global-require
  require('preact/debug');
}
