import { getLocationHash } from '@shared/models/location';
import {
  Position,
  LocationsShow,
  CitiesShow,
  LocationWithCity,
} from 'src/types';

export const calculateDistance = (p1: Position, p2: Position) =>
  Math.sqrt(
    (p1.latitude - p2.latitude) ** 2 + (p1.longitude - p2.longitude) ** 2
  );

export const addCityUuidToLocation = <T extends Position>(
  location: T,
  cities: CitiesShow[]
): T & { cityUuid: string } => {
  let cityUuid = cities[0].uuid;
  let distance = Number.POSITIVE_INFINITY;

  cities.forEach((city) => {
    const cityDistance = calculateDistance(city, location);
    if (distance > cityDistance) {
      distance = cityDistance;
      cityUuid = city.uuid;
    }
  });

  return {
    ...location,
    cityUuid,
  };
};

interface GetSelectedCityAndLocationOptions {
  cities: CitiesShow[];
  sessionCityUuid: string | undefined;
  locations: LocationsShow[];
  sessionLocation: LocationWithCity | undefined;
}

export const getSelectedCityAndLocation = (
  options: GetSelectedCityAndLocationOptions
) => {
  const { cities, sessionCityUuid, locations, sessionLocation } = options;
  if (
    sessionLocation &&
    cities.find((city) => city.uuid === sessionLocation.cityUuid)
  ) {
    const sessionLocationHash = getLocationHash(sessionLocation);
    locations.forEach((location) => {
      if (sessionLocationHash === getLocationHash(location)) {
        sessionLocation.uuid = location.uuid;
      }
    });

    return {
      cityUuid: sessionLocation.cityUuid,
      location: sessionLocation,
    };
  }

  const sessionCity = sessionCityUuid
    ? cities.find((city) => city.uuid === sessionCityUuid)
    : undefined;
  const locationsWithCities = locations.map((l) =>
    addCityUuidToLocation(l, cities)
  );

  if (sessionCity) {
    const location = locationsWithCities.find(
      (l) => l.cityUuid === sessionCity.uuid
    );
    return {
      cityUuid: sessionCity.uuid,
      location,
    };
  }

  if (locationsWithCities.length > 0) {
    const location = locationsWithCities[0];
    return {
      cityUuid: location.cityUuid,
      location,
    };
  }

  return {
    cityUuid: cities[0].uuid,
    location: undefined,
  };
};
