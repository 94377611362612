import i18next from 'i18next';
import en from './translations/en.json';
import ru from './translations/ru.json';

export const init = ({ lang }: { lang: string }) =>
  i18next.init({
    lng: lang,
    debug: process.env.NODE_ENV === 'development',
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
  });
