import { parse } from 'qs';
import {
  getMe,
  getLocations,
  getCities,
  getPhoneCountryCodes,
} from '@shared/modules/api';
import './debug';
import './polyfill';
import { localStorage } from 'src/localStorage';
import {
  addCityUuidToLocation,
  getSelectedCityAndLocation,
} from 'src/helpers/locations';
import { init as initI18n } from 'src/modules/i18n';
import { ProjectKey } from 'src/contexts/projects';
import { accessToken } from './configureApis';

export const bootstrap = async () => {
  const [session, cities, phoneCodes, { runApp }, smoothscroll] =
    await Promise.all([
      fetchSession().catch(() => undefined),
      getCities({ by: 'unscoped' }),
      getPhoneCountryCodes({ by: 'unscoped' }).catch(() => []),
      import('./runApp'),
      import('smoothscroll-polyfill'),
      initI18n({ lang: 'ru' }),
    ]);

  import('src/helpers/rollbar');
  smoothscroll.polyfill();

  const locations = (session?.locations || []).map((location) =>
    addCityUuidToLocation(location, cities)
  );

  runApp({
    cities,
    phoneCodes,
    ...getSelectedCityAndLocation({
      cities,
      locations,
      sessionCityUuid: localStorage.get('city'),
      sessionLocation: localStorage.get('location'),
    }),
    ...session,
    locations,
    projectKey: await getProjectKey(),
  });
};

const fetchSession = async () => {
  if (!accessToken) {
    return undefined;
  }

  const initialSession = await getMe();

  // FIXME
  // if (initialSession.kind !== SessionKind.customer) {
  //   throw new Error(`Session role is not supported: ${initialSession.kind}`);
  // }

  const locations = await getLocations({
    by: 'customer',
    customerUuid: initialSession.owner.uuid,
  });

  return {
    initialSession,
    locations,
  };
};

const getProjectKey = async (): Promise<ProjectKey> => {
  let projectKey = document.location.hostname.includes('schastie-dostavka.ru')
    ? 'schastie'
    : 'nf';

  const qs = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (['schastie', 'nf'].includes(qs.projectKey as ProjectKey)) {
    projectKey = qs.projectKey as ProjectKey;
  }

  const result = projectKey === 'schastie' ? 'schastie' : 'nf';

  if (result === 'schastie') {
    const title = document.getElementsByTagName('title')[0];
    title.innerText = 'Счастье';

    const description = document.querySelector(
      'meta[name="description"]'
    ) as HTMLElement;
    description.setAttribute('content', 'Счастье');

    const favicon = document.querySelector(
      'link[rel="shortcut icon"]'
    ) as HTMLElement;
    favicon.setAttribute('href', '/icons/schastie-favicon.ico');

    document.querySelector('link[type="image/png"]')?.remove();
    document.querySelector('link[rel="apple-touch-icon"]')?.remove();
    document.querySelector('meta[name="apple-itunes-app"]')?.remove();
  }

  return result;
};
