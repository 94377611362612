import { createStorage } from 'react-trick-local-storage';
import { LocationWithCity, UtmData, PaymentMethodKind } from 'src/types';
import { Cart } from './modules/cart/types';

interface Fields {
  accessToken: string | undefined;
  city: string | undefined;
  location: LocationWithCity | undefined;
  bankCardUuid: string | undefined;
  paymentMethod: PaymentMethodKind | undefined;
  cart: Cart | undefined;
  coupon: string | undefined;
  isInvitesWidgetDismissed: boolean | undefined;
  utm?: UtmData;
  debugPreact: boolean | undefined;
}

export const localStorage = createStorage<Fields>({
  key: process.env.REACT_APP_LOCAL_STORAGE_KEY,
});
